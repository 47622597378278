@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

.custom-auth__box__container {
  min-height: 90vh; // height of the browser viewport
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}

.custom-auth__box {
  padding-top: 25px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}
